import React from "react";

function ContentSection(props) {
  const { image, alt, text, id, title, textOne, textTwo, textThree, textFour } = props
  
  return (
    <section id={id} className="container md:py-20 py-10 px-5 flex flex-wrap md:flex-nowrap odd:flex-row-reverse even:flex-row justify-evenly justify-between gap-8">
      <article className="w-full md:w-3/6">
        <h2 className="relative lg:text-5xl md:text-3xl text-xl font-semibold uppercase mb-5 md:mb-7">{title}</h2>
        <div className="md:pr-6 text-justify">
          <p className="mb-3.5">{text}</p>
          <p className="mb-3.5">{textOne}</p>
          <p className="mb-3.5">{textTwo}</p>
          <p className="mb-3.5">{textThree}</p>
          <p className="mb-3.5">{textFour}</p>
        </div>
      </article>
      <aside className="relative w-full md:w-3/6 before:absolute before:left-[-30px] before:w-full before:block before:bg-sumpro-gray before:opacity-30 before:h-full before:z-[-1] z-0">
        <div className="relative w-full z-0 bottom-[-30px]">
          <img src={image} alt={alt} />
        </div>
      </aside>
    </section>
  )
}

export default ContentSection;