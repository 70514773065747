import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import OurTeam from "./pages/OurTeam";
import OurPlus from "./pages/OurPlus";
import Representation from "./pages/Representation";
import "./index.css"
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Cookies from "./pages/Cookie";
import News from "./pages/News";
import "./i18n";
import CookieBox from "./components/CookieBox";

const AppLayout = () => {
  return (
    <Suspense fallback="...loading">
      <Navbar />
      <Outlet />
      <Footer />
      <CookieBox />
    </Suspense>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "representation",
        element: <Representation />,
      },
      {
        path: "our-plus",
        element: <OurPlus />,
      },
      {
        path: "our-team",
        element: <OurTeam />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />,
      },
      {
        path: "cookie",
        element: <Cookies />,
      },
      {
        path: "news",
        element: <News />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
