import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/Hero/Herobanner";
import ContentSection from "../components/Sections/ContentSection";
import { useTranslation } from 'react-i18next';

function OurPlus() {
  const { t } = useTranslation();
  var sectionsourplus = t('sectionsourplus', { returnObjects: true }) // Return the array from my local JSON file
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = t("bannerourplus.desktopImage");
  const mobileImage = t("bannerourplus.mobileImage");
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="our-plus">
      <Helmet>
        <title>Our Plus</title>
        <meta name="description" content="Our extensive network enables us to forge meaningful partnerships, continually presenting innovative strategies alongside PR and media agencies to elevate our clients' public image." />
      </Helmet>
      <HeroBanner title={t("bannerourplus.title")} image={image} alt={t("bannerourplus.alt")} />
      {sectionsourplus.map((content, index) => (
        <ContentSection title={content.title} text={content.text} textOne={content.textOne} textTwo={content.textTwo} textThree={content.textThree} textFour={content.textFour} id={content.id} key={index} image={content.image} alt={content.alt} />
      ))}
    </div>
  );
}

export default OurPlus;