import React from "react";
import { Helmet } from "react-helmet";
import HomeBanner from "../components/Hero/Homebanner";
import ImageSection from "../components/Sections/ImageSection";
import Info from "../components/Sections/InfoSection";
import TextSection from "../components/Sections/TextSection";
import { useTranslation } from 'react-i18next';

function Home() {
  const { t } = useTranslation();
  
  return (
    <div className="home">
      <Helmet>
        <title>Sumpro Global Sports Agency</title>
        <meta name="description" content="Sumpro is a cutting-edge sports agency that is redefining sports management to a whole new level." />
      </Helmet>
      <HomeBanner />
      <TextSection title={t("about.header")} boldText={t("about.boldText")} para={t("about.para")} paraOne={t("about.paraOne")} paraTwo={t("about.paraTwo")} />
      <Info />
      <ImageSection />
    </div>
  );
}

export default Home;