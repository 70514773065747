const link = [
  { 
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
  { 
    title: "Cookie Policy",
    url: "/cookie",
  },
]

export default link;