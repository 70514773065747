import React, { useState, useEffect } from "react";
import {ReactComponent as ReactLogo} from '../../svg/logo.svg';

function HomeBanner() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = "/desktop-banner/home-banner.jpg";
  const mobileImage = "/mobile-banner/home-banner-mobile.jpg";
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  const desktopImageWidth = 2504;
  const mobileImageWidth = 650;
  const imageSize = windowWidth >= 650 ? desktopImageWidth : mobileImageWidth;
  const desktopImageHeight = 1375;
  const mobileImageHeight = 640;
  const imageHeight = windowWidth >= 650 ? desktopImageHeight : mobileImageHeight;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <section className="relative flex justify-center items-center w-screen max-h-[40rem] overflow-hidden capitalize">
      <div className="overflow-hidden flex flex-wrap flex-col justify-center items-center absolute z-[1] w-screen h-full">
        <div className="relative w-[80%] max-w-[730px] h-screen flex flex-wrap flex-col justify-center items-center mt-[15%] md:mt-[5%]">
          <ReactLogo />
        </div>
      </div>
      <img className="opacity-60 object-cover w-screen h-screen" src={image} alt="Welcome to Sumpro Global Sports Agency" width={imageSize} height={imageHeight} />
    </section>
  )
}

export default HomeBanner;