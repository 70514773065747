import React, { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";

function ScrollButton() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <div className="lg:hidden flex justify-center items-center top-to-btm bg-sumpro-green w-[44px] h-[44px] rounded-md">
      {" "}
      {showTopBtn && (
        <FaAngleUp
          className="text-3xl"
          onClick={goToTop}
        />
      )}{" "}
    </div>
  )
}

export default ScrollButton;