import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/Hero/Herobanner";
import ContentSection from "../components/Sections/ContentSection";
import { useTranslation } from 'react-i18next';

function Representation() {
  const { t } = useTranslation();
  var sectionsrepresentation = t('sectionsrepresentation', { returnObjects: true }); // Return the array from my local JSON file
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = t("bannerrepresentation.desktopImage");
  const mobileImage = t("bannerrepresentation.mobileImage");
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="representation">
      <Helmet>
        <title>Representation</title>
        <meta name="description" content="Sumpro will handle all aspects of your career, from negotiating transfers, salaries, and loan moves to securing lucrative sponsorship deals and beyond" />
      </Helmet>
      <HeroBanner title={t("bannerrepresentation.title")} image={image} alt={t("bannerrepresentation.alt")} />
      {sectionsrepresentation.map((content, key) => (
        <ContentSection title={content.title} text={content.text} textOne={content.textOne} textTwo={content.textTwo} textThree={content.textThree} textFour={content.textFour} id={content.id} key={key} image={content.image} alt={content.alt} />
      ))}
    </div>
  );
}

export default Representation;