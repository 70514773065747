import React from "react";
import { FacebookEmbed } from 'react-social-media-embed';

class Post extends React.Component {
  render () {
    const {desc} = this.props.details;
    const {order} = this.props.details;
    const index = this.props.index;
    // console.log(index);
    // console.log(order);
    
    return (
      <div className="mb-10 w-full max-w-screen-sm">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FacebookEmbed url={desc} width={640} index={index} order={order} />
        </div>
      </div>
    );
  }
}

export default Post;