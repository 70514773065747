import React, { useState, useEffect } from "react";
import HeroBanner from "../Hero/Herobanner";
import { useTranslation } from 'react-i18next';

function NewsBanner() {
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = t("bannernews.desktopImage");
  const mobileImage = t("bannernews.mobileImage");
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <HeroBanner title={t("bannernews.title")} image={image} alt={t("bannernews.alt")} />

  );
}

export default NewsBanner;