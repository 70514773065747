import React, { useEffect } from "react";

function CookieBox() {
  const cookieStorage = {
    getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map(cookie => cookie.split('='))
            .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
        return cookies[item];
    },
    setItem: (item, value) => {
        document.cookie = `${item}=${value};`
    }
  };

  const storageType = cookieStorage;
  const consentPropertyName = "sumproagency_consent";

  const shouldShowPopup = () => !storageType.getItem(consentPropertyName);
  const saveToStorage = () => storageType.setItem(consentPropertyName, true);

  useEffect(() => {
    const acceptFn = () => {
      saveToStorage(storageType);
      consentPopup.classList.add('hidden');
    };

    const consentPopup = document.getElementById('consent-popup');
    const acceptBtn = document.getElementById('accept');

    acceptBtn.addEventListener('click', acceptFn);

    if (shouldShowPopup(storageType)) {
      setTimeout(() => {
          consentPopup.classList.remove('hidden');
      }, 2000);
    }
  },);

  return (
    <div id="consent-popup" className="hidden w-full flex items-center justify-center text-center bg-sumpro-bg duration-[8s] transition-opacity fixed bottom-0 opacity-1 flex flex-col justify-center items-center z-50">
      <div className="relative w-full p-7">
        <span className="text-xs">We use cookies to optimise site functionality and give you the best possible experience. You can manage your cookie preferences or find out more by referring to our cookie policy.</span>
        <button id="accept" className="bg-sumpro-green px-2 hover:bg-sumpro-gray ml-1">Ok</button>
      </div>
    </div>
  );
}

export default CookieBox;