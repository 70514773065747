import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { faFutbol } from '@fortawesome/free-regular-svg-icons'
import { faGavel, faChartLine, faCoins } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  &:nth-child(1) {
    div {
      div {
        span {
          &:nth-child(1) {
            display: block;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }
  &:nth-child(2) {
    div {
      div {
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }
  &:nth-child(3) {
    div {
      div {
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: block;
          }
          &:nth-child(4) {
            display: none;
          }
        }
      }
    }
  }
  &:nth-child(4) {
    div {
      div {
        span {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            display: block;
          }
        }
      }
    }
  }
`
const IconSmall = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const IconBig = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

function Info() {
  const { t } = useTranslation();
  var info = t('info', { returnObjects: true }) // Return the array from my local JSON file

  useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
        } else { 
        }
    });
  }, []);


  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  const element = <FontAwesomeIcon icon={faFutbol} />
  const element1 = <FontAwesomeIcon icon={faChartLine} />
  const element2 = <FontAwesomeIcon icon={faGavel} />
  const element3 = <FontAwesomeIcon icon={faCoins} />

  return (
    <div className="container md:py-20 py-10 px-5">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-8">
        {info.map((content) => (
          <Wrapper className="odd:bg-sumpro-green even:bg-sumpro-blue rounded-xl h-52 min-h-full" key={content.id}>
            <div className="relative px-8 pt-10 overflow-hidden h-full">
              <div className="big-font flex justify-end absolute bottom-[-20px] right-[-30px] opacity-10 text-[160px] text-justify">
              <IconBig>{element}</IconBig>
              <IconBig>{element1}</IconBig>
              <IconBig>{element2}</IconBig>
              <IconBig>{element3}</IconBig>
              </div>
              <div className="small-font flex justify-start opacity-40 h-12 text-[50px] text-justify">
              <IconSmall>{element}</IconSmall>
              <IconSmall>{element1}</IconSmall>
              <IconSmall>{element2}</IconSmall>
              <IconSmall>{element3}</IconSmall>
              </div>
              <h2 className="text-2xl font-semibold uppercase mb-2.5">{t(content.title)}</h2>
              <Link aria-label="Read more about what we do" to={t(content.link)} onClick={goToTop} className="capitalize font-semibold hover:underline">{t(content.linkName)}</Link>
            </div>
          </Wrapper>
        ))}
      </div>
    </div>
  )
}

export default Info;