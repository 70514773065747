import React from "react";

function Transfermarkt() {
  return (
    <div className="py-7 px-5 md:px-0">
      <a className="block bg-sumpro-gray hover:opacity-60 justify-center items-center" target="_blank" rel="noreferrer" href="https://www.transfermarkt.com/sumpro-global-sports-agency-ltd/beraterfirma/berater/11825" aria-label="Read more about us on Facebook">
        <img className="relative" src="/transfermarkt-logo-cut-small.jpg" alt="Transfermarkt Logo" width="128" height="51" />
      </a>
    </div>
  )
}

export default Transfermarkt;