import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTwitter, faFacebookF, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Socialicons() {
  const facebook = <FontAwesomeIcon icon={faFacebookF} height="24px" />
  const twitter = <FontAwesomeIcon icon={faTwitter} height="24px" />
  const youtube = <FontAwesomeIcon icon={faYoutube} height="24px" />
  
  return (
    <div className="py-7 flex flex-wrap flex-col items-center px-5 md:px-0">
      <h3 className="lg:text-5xl text-3xl font-semibold pb-5">Follow Us</h3>
      <ul className="flex flex-wrap flex-row justify-center">
        <li className="p-1">
          <a className="block bg-sumpro-gray hover:bg-sumpro-green w-16 h-16 flex justify-center items-center rounded-full text-2xl" target="_blank" rel="noreferrer" href="https://www.facebook.com/sumproagency" aria-label="Read more about us on Facebook">
            {facebook}
          </a>
        </li>
        <li className="p-1">
          <a className="block bg-sumpro-gray hover:bg-sumpro-green w-16 h-16 flex justify-center items-center rounded-full text-2xl" target="_blank" rel="noreferrer" href="https://www.twitter.com/sumproagency" aria-label="Read more about us on Twitter">
            {twitter}
          </a>
        </li>
        <li className="p-1">
          <a className="block bg-sumpro-gray hover:bg-sumpro-green w-16 h-16 flex justify-center items-center rounded-full text-2xl" target="_blank" rel="noreferrer" href="https://www.youtube.com/@sumproagency" aria-label="Read more about us on Youtube">
            {youtube}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Socialicons;