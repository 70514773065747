import React from "react";

function Blogo() {
  return (
    <div className="py-7 px-5 md:px-0">
      <img className="relative" src="/badge-logo.png" alt="Logo" width="698" height="1185" />
    </div>
  )
}

export default Blogo;