import React from "react";

function TextSection(props) {
  const { boldText, title, para, paraOne, paraTwo } = props
  
  return (
    <section className="container md:py-20 py-10 px-5 flex justify-center text-center">
      <article className="w-full max-w-screen-md">
        <h1 className="relative lg:text-4xl md:text-4xl text-3xl font-semibold capitalize mb-5 md:mb-7">{title}</h1>
        <div className="text-justify">
          <p className="font-semibold mb-4 block">{boldText}</p>
          <p className="mb-3.5">{para}</p>
          <p className="mb-3.5">{paraOne}</p>
          <p className="mb-3.5">{paraTwo}</p>
        </div>
      </article>
    </section>
  )
}

export default TextSection;