import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import HeroBanner from "../components/Hero/Herobanner";
import banner from "../content/privacy-banner-content";

function PrivacyPolicy() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = banner.desktopImage;
  const mobileImage = banner.mobileImage;
  const image = windowWidth >= 650 ? desktopImage : mobileImage;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
      } else { 
      }
    });

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  return (
    <div className="privacy-policy">
      <HeroBanner title={banner.title} id={banner.id} image={image} alt={banner.alt} key={banner.index} />
      <section id="privacy-policy" className="container md:py-20 py-10 px-5 lg:px-0 flex flex-wrap md:flex-nowrap odd:flex-row-reverse even:flex-row justify-evenly justify-between gap-8">
        <article className="w-full max-w-4xl">
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Website Privacy Policy</h2>

            <p className="mb-4">This website is operated by SUMPRO GLOBAL SPORTS AGENCY. The privacy of our users is extremely important to us and therefore we encourage all users to read this policy very carefully because it contains important information regarding:</p>

            <ul className="list-disc list-inside">
              <li className="ml-6">who we are;</li>

              <li className="ml-6">how and why we collect, store, use and share personal information;</li>

              <li className="ml-6">your rights in relation to your personal information; and</li>

              <li className="ml-6">how to contact us and supervisory authorities in the event that you have a complaint.</li>
            </ul>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Who we are</h2>

            <p>SUMPRO GLOBAL SPORTS AGENCY (&#39;we&#39;, &#39;us&#39;, &#39;our&#39;) collect, use and are responsible for storing certain personal information about you (&#39;you&#39;, &#39;your&#39;, &#39;yours&#39;).</p>

          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">The personal information we collect and use</h2>

            <p>Personal information is information which you can be identified from (and does not include any anonymised forms of information).</p>
          
            <h3 className="text-2xl font-semibold mb-5 mt-5 ml-6">1. Types of personal information</h3>

            <p className="ml-6 mb-4">We may process the following types of personal information in relation to you:</p>

            <p className="ml-6">Sumpro Global Sports Agency Ltd<br/> 40 leman street<br/> E1 8EU LONDON<br/> info@sumproagency.com</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">How your personal information is collected</h2>

            <p>This section describes how the above types of personal information are collected by us. Your personal information will be collected as follows:</p>
            
            <h3 className="text-2xl font-semibold mb-5 mt-5 ml-6">1. Personal information obtained by use of cookies or other automated means</h3>

            <p className="ml-6 mb-4">We will sometimes obtain information via automated technology. This shall be by use of cookies and other similar technology. A cookie is a small text file which is placed onto your computer or electronic device when you access our website. Similar technologies include web beacons, action tags, local shared objects ('flash cookies') and single-pixel gifs. Such technologies can be used to track users' actions and activities, and to store information about them. Usually this will be in order to monitor and obtain information regarding:</p>

            <p className="ml-6 mb-4">Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>

            <p className="mb-4">On the first occasion that you use our site we will ask whether you consent to our use of cookies and/or other similar processing technologies. If you do not consent, such technologies will not be used. Thereafter you can opt-out of using cookies at any time or you can set your browser not to accept cookies. However, some of our website features may not function as a result.</p>

            <p className="mb-4">For further information on our use of cookies and other similar technologies, please see our policy document as described below.</p>
            
            <h3 className="text-2xl font-semibold mb-5 mt-5 ml-6">2. Changes to the way in which we collect your personal information</h3>

            <p className="ml-6">In the event that we need to obtain personal information in relation to you from any other source than those described above, we shall notify you of this.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">How we use your personal information</h2>

            <h3 className="text-2xl font-semibold mb-5 mt-5 ml-6">1. General purposes</h3>

            <p className="ml-6 mb-4">In general, your personal information will generally be processed for the following purposes:</p>

            <ul className="ml-6 list-disc list-inside">
              <li>You may supply us with information about you by filling in the contact us forms on our website.</li>
              <li>This includes information you provide when you submit an enquiry form. The information you give us may include your name and e-mail address.</li>
            </ul>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Lawful basis for processing of your personal information</h2>

            <p>We have described above the purposes for which we may process your personal information. These purposes will at all times be justified by UK data protection law.</p>
            
            <h3 className="text-2xl font-semibold mb-5 mt-5 ml-6">1. General lawful bases</h3>

            <p className="ml-6">The lawful basis upon which we are able to process your personal data are:</p>

            <ul className="ml-6">
              <li className="mb-4">(1) where we have your consent to use your data for a specific purpose;</li>

              <li className="mb-4">(2) where it is necessary to enter into a legal contract with you or to perform obligations under a legal contract with you;</li>

              <li className="mb-4">(3) where it is necessary to enable us to comply with a legal obligation;</li>

              <li className="mb-4">(4) where it is necessary to ensure our own legitimate interests or the legitimate interests of a third party (provided that your own interests and rights do not override those interests). Wherever we rely upon this basis, details of the legitimate interests concerned shall be provided to you;</li>

              <li className="mb-4">(5) where we need to protect your own vital interests (or the vital interests of another person); and/or</li>

              <li className="mb-4">(6) where it is needed in the public interest (or where we are acting in our official functions), provided that the task or function has a clear basis in law.</li>
            </ul>

            <p>In general, in order to meet the purposes we have described above, we will process your personal information where we have your express consent on each occasion that the data is processed</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Sharing of your personal information</h2>

            <p className="mb-4">On any occasion where any of your personal information is shared with any third party, we shall only permit them to process such information for our required purposes, under our specific instruction, and not for their own purposes. We are required to enter into a formal legal agreement to enable such sharing to take place.</p>

            <p className="mb-4">In order to meet the purposes we have described above, we may on some occasions need to share your personal information with other third parties. Those third parties will be:</p>

            <p className="ml-6">Football clubs, agencies, sponsorships</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">How long your personal information will be kept</h2>

            <p className="mb-4">Your personal information will only be kept for the period of time which is necessary for us to fulfil the above purposes.</p>

            <p className="mb-4">We envisage that your personal information shall be retained by us as follows:</p>

            <p>After the period described above, your information shall be properly deleted or anonymised.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Keeping your information secure</h2>

            <p className="mb-4">We will ensure the proper safety and security of your personal information and have measures in place to do so. We will also use technological and organisation measures to keep your information secure. These measures are as follows:</p>

            <p className="mb-4">We are ISO 27001 certified. This certification assists us in ensuring the safety of your personal information.</p>

            <p>We have proper procedures in place to deal with any data security breach, which shall be reported and dealt with in accordance with data protection laws and regulations. You shall also be notified of any suspected data breach concerning your personal information.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Children</h2>

            <p>Our website is not intended for children (anybody under the age of 18). We do not intend to collect data from children.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Your rights</h2>

            <p className="mb-4">Under the UK General Data Protection Regulation you have a number of important rights free of charge. In summary, those include rights to:</p>

            <ul className="ml-6">
              <li className="mb-4">(1) fair processing of information and transparency over how we use your use personal information;</li>

              <li className="mb-4">(2) access to your personal information and to certain other supplementary information that this Privacy Statement is already designed to address;</li>

              <li className="mb-4">(3) require us to correct any mistakes in your information which we hold;</li>

              <li className="mb-4">(4) require the erasure of personal information concerning you in certain situations;</li>

              <li className="mb-4">(5) receive the personal information concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit this information to a third party in certain situations;</li>

              <li className="mb-4">(6) object at any time to processing of personal information concerning you for direct marketing;</li>

              <li className="mb-4">(7) object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you;</li>

              <li className="mb-4">(8) object in certain other situations to our continued processing of your personal information, or ask us to suspend the processing procedure in order for you confirm its assurance or our reasoning for processing it;</li>

              <li className="mb-4">(9) object to processing our your personal information where we are doing so in reliance upon a legitimate interest of our own or of a third party and where you wish to raise to an objection to this particular ground;</li>

              <li className="mb-4">(10) otherwise restrict our processing of your personal information in certain circumstances;</li>

              <li className="mb-4">(11) claim compensation for damages caused by our breach of any data protection laws; and/or</li>

              <li className="mb-4">(12) in any circumstance where we rely upon your consent for processing personal information, you may withdraw this consent at any time.</li>
            </ul>

            <p className="mb-4">For further information on each of those rights, including the circumstances in which they apply, see the Guidance from the UK Information Commissioner&#39;s Office (ICO) on your rights under the General Data Protection Regulations.</p>

            <p className="mb-4">If you would like to exercise any of these rights please contact MANAGEMENT in the following manner:</p>

            <p className="ml-6">via email at info@sumproagency.com</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Your Requirements</h2>

            <p>If you would like this policy in another format (for example: audio, large print, braille) please contact us using the details below.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Complaints procedure</h2>

            <p className="mb-4">We hope that we can resolve any query or concern you raise about our use of your information.</p>

            <p>The UK General Data Protection Regulation also gives you right to lodge a complaint with the supervisory authority. The supervisory authority in the United Kingdom is the Information Commissioner.</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Changes to the privacy policy</h2>
            <p className="mb-4">This privacy policy was published on 13th March 2023 and last updated on 13th March 2023.</p>

            <p className="mb-4">We may change this privacy policy from time to time and will notify you of any changes by:</p>

            <p className="ml-6">By a notice on the website header</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Contacting us</h2>
            <p className="mb-4">The relevant person to contact regarding your personal information is: MANAGEMENT.</p>

            <p className="mb-4">Any requests or questions regarding the use of your personal information should be made to the above named person using the following method:</p>

            <p>via email at info@sumproagency.com</p>
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Sources of further Information</h2>

            <p className="mb-4">This policy provides key information to you regarding the processed of your information. For certain areas of our information processing, we have further comprehensive details contained in other documentation. This information can be located as follows:</p>

            <ul className="list-disc list-inside mb-4">
              <li className="ml-6">Our policy regarding the use of cookies and other similar technologies entitled COOKIE can be located at <NavLink to="/cookie" className="underline" onClick={() => {handleClick(); goToTop();}}>Cookie Policy</NavLink></li>
            </ul>
          </section>
        </article>
      </section>
    </div>
  );
}

export default PrivacyPolicy;