import React from "react";
import firebase from "firebase";
import base, { firebaseApp } from "../../base";
import AddPostForm from "./AddPostForm";
import EditPostForm from "./EditPostForm";
import Login from "./Login";


class ContentManagement extends React.Component {
  state = {
    uid: null,
    owner: null
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        this.authHandler({ user });
      }
    })
  };

  authHandler = async (authData) => {
    // 1. Look up the current path in the firebase database
    const path0 = "/news";
    const path = await base.fetch(path0, { context: this });
    
    // 2. Claim it if there is no owner
    if (!path.owner) {
      // save it as our own
      await base.post(`${path0}/owner`, {
        data: authData.user.uid
      });
    }
    // 3. Set the state of the Content Management component to reflect the current user
    this.setState({
      uid: authData.user.uid,
      owner: path.owner || authData.user.uid
    })
  };

  authenticate = provider => {
    const authProvider = new firebase.auth.GoogleAuthProvider();
    firebaseApp.auth().signInWithPopup(authProvider).then(this.authHandler);
  };

  logout = async () => {
    await firebase.auth().signOut();
    this.setState({ uid: null });
  };
  
  render () {
    const logout = <button onClick={this.logout} className="my-2.5 min-w-[50%] rounded-md bg-sumpro-green px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Log Out!</button>;

    // 1. Check if they are logged in
    if (!this.state.uid) {
      return <Login authenticate={this.authenticate} />;
    }
    // 2. Check if they are not the owner of the posts
    // To do - comment this logging and how it prints the error or the content desired 
    if (this.state.uid !== this.state.owner) {
      return (
        <div>
          <p>Sorry you are not the owner!</p>
          {logout}
        </div>
      )
    }
    // 3. They must be the owner, just render the inventory
    return (
      <div className="flex flex-col items-center">
        <h2 className="relative md:text-3xl text-2xl font-semibold mb-5 md:mb-7">
          Add new Posts or Edit old Posts
        </h2>
        {logout}

        <AddPostForm addPost={this.props.addPost} />

        {Object.keys(this.props.posts).map(key => (
          <EditPostForm 
            key={key}
            index={key}
            post={this.props.posts[key]}
            updatePost={this.props.updatePost}
            deletePost={this.props.deletePost}
          />
        ))}
      </div>
    );
  }
}

export default ContentManagement;