import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/Hero/Herobanner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/ContactForm";
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  const locationDot = <FontAwesomeIcon icon={faLocationDot} />
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = t("bannercontact.desktopImage");
  const mobileImage = t("bannercontact.mobileImage");
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="contact">
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="We are here to stand by your side, offering comprehensive assistance and ensuring that you have a strong support network." />
      </Helmet>
      <HeroBanner title={t("bannercontact.title")} image={image} alt={t("bannercontact.alt")} />
      <section className="flex min-h-full justify-center container md:py-20 py-10 px-5 md:px-0">
        <div className="relative px-8 pt-10 pb-20 overflow-hidden h-full max-w-xl bg-sumpro-blue rounded-xl">
          <div className="flex justify-end absolute bottom-[-20px] right-[-30px] opacity-10 text-[160px] text-justify">
            {locationDot}
          </div>
          <div className="flex justify-start opacity-40 h-12 text-[50px] text-justify">
            {locationDot}
          </div>
          <h2 className="text-2xl font-semibold uppercase mb-2.5 mt-2.5">SUMPRO Global Sports Agency LTD</h2>
          <address>
            40 Leman Street,<br />
            E1 8EU London (UK)
          </address>
        </div>
      </section>
      <section className="flex max-w-3xl justify-center container md:py-20 py-10 px-5 md:px-0">
        <ContactForm />
      </section>
    </div>
  );
}

export default Contact;