import React from "react";

class AddPostForm extends React.Component {
  descRef = React.createRef();
  orderRef = React.createRef();

  createPost = (event) => {
    // 1. stop the form from submitting
    event.preventDefault();
    const post = {
      desc: this.descRef.current.value,
      order: parseFloat(this.orderRef.current.value)
    };
    this.props.addPost(post);
    // refresh the form
    event.currentTarget.reset();
  };

  render () {
    return (
      <form className="flex flex-col items-center max-w-lg w-full mt-5" onSubmit={this.createPost}>
        <textarea className="h-32 min-h-full block w-full rounded-md border-0 p-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="desc" ref={this.descRef} placeholder="Text"></textarea>
        <input className="block w-full rounded-md border-0 p-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="order" ref={this.orderRef} type="text" placeholder="Order" />
        <button type="submit" className="my-2.5 min-w-[50%] rounded-md bg-sumpro-green px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">+ Add Post</button>
      </form>
    );
  }
}

export default AddPostForm;