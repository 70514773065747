import React, { useState, useEffect } from "react";

function HeroBanner(props) {
  const { image, id, title, alt } = props;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImageWidth = 1640;
  const mobileImageWidth = 650;
  const imageSize = windowWidth >= 650 ? desktopImageWidth : mobileImageWidth;

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <section className="relative flex justify-center items-center w-screen max-h-[36rem] overflow-hidden capitalize" id={id}>
      <div className="flex flex-wrap flex-col justify-center items-center absolute z-[1] w-screen h-full">
        <h1 className="lg:text-7xl md:text-5xl text-3xl font-semibold text-sumpro-white font-semibold tracking-wide mb-10">{title}</h1>
      </div>
      <div className="relative flex justify-center items-center max-h-[36rem] w-full">
        <img className="opacity-30 box-content object-cover w-full" src={image} alt={alt} width={imageSize} height="576" />
      </div>
    </section>
  )
}

export default HeroBanner;