import React from "react";

function Copyrights() {
  return (
    <div className="w-full max-w-4xl py-10 flex flex-col items-center justify-center text-center px-5 md:px-0 text-base">
      <p className="text-sm">
        © All Rights Reserved by SUMPRO GLOBAL SPORTS AGENCY LTD
      </p>
      <br/>
      <p className="text-sm">info@sumproagency.com</p>
      <p className="text-sm">SUMPRO GLOBAL SPORTS AGENCY LTD <br/>
      40 Leman Street, E1 8EU London</p>
      <br/>
      <p className="text-sm">Sumpro global sports agency is a limited company registered in the UK.</p>
      <p className="text-sm">Company nr: 14748416 | Vat nr: 442 7086 93</p>
      <p className="text-sm">Julien C. Briquez (C.E.O)</p>
      <p className="text-sm">Licenced FIFA Agent ID  202304-581</p>
      <p className="text-sm">FA intermediary IMS006702</p>
      <p className="text-sm">FIFA Safeguards certifications (silver, gold, platinum)</p>
    </div>
  )
}

export default Copyrights;