import React from "react";

function Flogo() {
  return (
    <div className="py-7 px-5 md:px-0">
      <img className="relative" src="/footer-logo.png" alt="Sumpro Global Sports Agency logo" width="263" height="56" />
    </div>
  )
}

export default Flogo;