import React from "react";
import NewsBanner from "../components/News/Newsbanner";
import Post from "../components/News/Post";
import ContentManagement from "../components/News/ContentManagement";
import base from "../base";

class News extends React.Component {
  state = {
    posts: {},
    path: "",
  };
  
  componentDidMount() {
    const path = "/news";
    this.ref = base.syncState(`${path}/posts`, {
      context: this,
      state: 'posts'
    });
  }
  
  componentWillUnmount() {
    base.removeBinding(this.ref);
  };

  addPost = post => {
    // 1. take a copy of existing state
    const posts = {...this.state.posts};
    // 2. add that new post to that posts var
    posts[`post${Date.now()}`] = post;
    // const sort = order.sort((a, b) => (a > b ? -1 : 1))
    // 3. Set the new posts object to state
    this.setState({
      posts: posts
    });
  };

  updatePost = (key, updatedPost) => {
    // 1. take a copy of current state
    const posts = { ...this.state.posts };
    // 2. update that state
    posts[key] = updatedPost; 
    // 3. Set that to state
    this.setState({
      posts: posts
    });
  };

  deletePost = (key) => {
    // 1. Take a copy of the current state
    const posts = {...this.state.posts};
    // 2. Update that state
    posts[key] = null;
    // 3. Update state
    this.setState({ posts });
  };

  render() {
    // const set = new Set([a, b, c, d]);
    // const result = Array.from(set).sort();
    // console.log(result); // 👉️ ['a', 'b', 'c']

    return (
      <div className="news">
        <NewsBanner />
        <section id="news" className="container md:py-20 py-10 px-5">
          <div className="flex flex-col items-center">
            {Object.keys(this.state.posts).map((key, index, order) => 
              <Post key={key} index={index} details={this.state.posts[key]} order={this.state.posts[order]} />
            )}
          </div>
        </section>
        <section id="cms" className="flex flex-col items-center container md:py-20 py-10 px-5 w-full">
          <ContentManagement 
            addPost={this.addPost} 
            updatePost={this.updatePost}
            deletePost={this.deletePost}
            posts={this.state.posts} 
            path={this.props}
          />
        </section>
      </div>
    )
  }
}

export default News;