import Rebase from "re-base";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyC_XH1Kj8lT1g01vwQXfXhGfluD9lLnYtg",
  authDomain: "sumproagency-3e95f.firebaseapp.com",
  databaseURL: "https://sumproagency-3e95f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sumproagency-3e95f",
  storageBucket: "sumproagency-3e95f.appspot.com",
  messagingSenderId: "662541484830",
  appId: "1:662541484830:web:260c4ce31e3832590c7f0f",
  measurementId: "G-YWK7VB104G"
})

const base = Rebase.createClass(firebaseApp.database());

// this is named export
export {firebaseApp};

// this is default export
export default base;