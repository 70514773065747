import React, { useEffect } from "react";
import Footernav from "./Footernav";
import Socialicons from "./Socialicons";
import Copyrights from "./Copyrights";
import Flogo from "./Flogo";
import Blogo from "./Blogo";
import ScrollButton from "./Scrollbutton";
import PrivacyLink from "./PrivacyLink";
import link from "../../content/privacy-link";
import { useTranslation } from 'react-i18next';
import Transfermarkt from "./Transfermarkt";

function Footer() {
  const { t } = useTranslation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
           
        } else {
            
        }
    });
  }, []);

  var footermenu = t('footermenu', { returnObjects: true }) // Return the array from my local JSON file

  return (
    <footer className="bg_img bg-sumpro-overlay">
      <div className="container container-mobile">
        <div className="lg:py-[100px] py-10 flex flex-col items-center">
          <Socialicons />
          <Flogo />
          <Transfermarkt />
          <Blogo />
        </div>
        <div className="pt-10 border-t border-sumpro-gray flex flex-wrap flex-col items-center justify-center">
          <div className="flex flex-wrap flex-col items-center">
            <ul className="lg:flex flex-wrap flex-row justify-center px-5 md:px-0 hidden">
              {footermenu.map((content, index) => (
                <Footernav title={content.title} url={content.url} key={index} />
              ))}
              {link.map((content, index) => (
                <PrivacyLink title={content.title} url={content.url} key={index} />
              ))}
            </ul>
            <ul className="flex flex-wrap flex-row justify-center px-5 md:px-0 lg:hidden">
              {link.map((content, index) => (
                <PrivacyLink title={content.title} url={content.url} key={index} />
              ))}
            </ul>
          </div>
          <ScrollButton />
          <Copyrights />
        </div>
      </div>
    </footer>
  )
}

export default Footer;