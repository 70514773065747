// Make sure to run npm install @formspree/react
// For more help visit https://formspr.ee/react-help
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function ContactForm() {
  const [state, handleSubmit] = useForm("xlekqpzv");
  if (state.succeeded) {
      return (
        <section className="flex flex-col min-h-full justify-center container md:py-20 py-10 px-5 md:px-0 text-center">
          <p className="text-2xl">Thank you for getting in touch!</p>
          <p>We appreciate you contacting us/ SUMPRO Global Sports Agency. One of our colleagues will get back in touch with you soon! Have a great day!</p>
        </section>
      )

  }
  return (
    <form onSubmit={handleSubmit} className="flex flex-col justify-stretch min-w-full">
      <label htmlFor="name" className="font-semibold uppercase text-white mb-2.5 mt-6">
        Full Name
      </label>
      <input
        id="name"
        type="text" 
        name="name" className="form-input px-4 py-3 text-white rounded-xl bg-transparent border-2 border-sumpro-green focus:bg-white focus:text-sumpro-blue"
        placeholder="John Smith"
      />
      <ValidationError 
        prefix="Name" 
        field="text"
        errors={state.errors}
      />
      <label htmlFor="email" className="font-semibold uppercase text-white mb-2.5 mt-6">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        className="form-input px-4 py-3 text-white rounded-xl bg-transparent border-2 border-sumpro-green focus:bg-white focus:text-sumpro-blue "
        placeholder="youremail@email.com"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
      <label htmlFor="message" className="font-semibold uppercase text-white mb-2.5 mt-6">
        Message
      </label>
      <textarea
        id="message"
        name="message"
        className="form-input px-4 py-3 text-white rounded-xl bg-transparent border-2 border-sumpro-green focus:bg-white focus:text-sumpro-blue h-52"
        placeholder="Hello..."
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
        <button type="submit" disabled={state.submitting} className="border rounded-xl border-sumpro-green hover:bg-sumpro-green font-semibold uppercase text-white mb-2.5 mt-6 px-4 py-6 max-w-xs">
          Submit
        </button>
    </form>
  );
}

export default ContactForm;