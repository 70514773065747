import React from "react";

class EditPostForm extends React.Component {
  
  handleChange = event => {
    // Update that post
    // 1. Take a copy of the current post
    const updatedPost = {
      ...this.props.post,
      [event.currentTarget.name]: event.currentTarget.value
    };
    // 2. update that post
    this.props.updatePost(this.props.index, updatedPost);
  };

  render () {
    return (
      <div className="post-edit flex flex-col items-center max-w-lg w-full mt-5" onSubmit={this.createPost}>
        <textarea className="h-32 min-h-full block w-full rounded-md border-0 p-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="desc" onChange={this.handleChange} value={this.props.post.desc}></textarea>
        <input className="block w-full rounded-md border-0 p-3.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" name="order" onChange={this.handleChange} value={this.props.post.order} type="text" placeholder="Order" />
        <button className="my-2.5 min-w-[50%] rounded-md bg-sumpro-green px-3 py-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" type="submit" onClick={() => this.props.deletePost(this.props.index)}>Remove Post</button>
      </div>
    );
  }
}

export default EditPostForm;