import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import HeroBanner from "../components/Hero/Herobanner";
import ContentSection from "../components/Sections/ContentSection";
import { useTranslation } from 'react-i18next';
import ProfileSection from "../components/Sections/ProfileSection";
import profiles from "../content/profiles-content";

function OurTeam() {
  const { t } = useTranslation();
  var sectionsourteam = t('sectionsourteam', { returnObjects: true }) // Return the array from my local JSON file
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = t("bannerourteam.desktopImage");
  const mobileImage = t("bannerourteam.mobileImage");
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="our-team">
      <Helmet>
        <title>Our Team</title>
        <meta name="description" content="Our expertise extends beyond ex-professional football players, coaches, and international scouts." />
      </Helmet>
      <HeroBanner title={t("bannerourteam.title")} image={image} alt={t("bannerourteam.alt")} />
      <section className="container md:py-20 py-10 px-5 flex justify-center text-center">
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 gap-8">
          {profiles.map((profiles, key) => (
            <ProfileSection name={profiles.name} position={profiles.position} languadge={profiles.languadge} image={profiles.image} key={key} />
          ))}
        </div>
      </section>
      {sectionsourteam.map((content, index) => (
        <ContentSection title={content.title} text={content.text} textOne={content.textOne} textTwo={content.textTwo} textThree={content.textThree} textFour={content.textFour} id={content.id} key={index} image={content.image} alt={content.alt} />
      ))}
    </div>
  );
}

export default OurTeam;