import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from '../logo.png';
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { NavLink } from "react-router-dom";
import Flags from "../components/Flags";
import { useTranslation } from 'react-i18next';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
           
        } else {
            
        }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };

  const { t } = useTranslation();
  var menu = t('menu', { returnObjects: true }) // Return the array from my local JSON file

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
          <nav className="sticky bg-sumpro-bg z-10 top-0 w-full transition-all flex justify-center items-center">
            <div className="relative flex justify-between h-20 container">
              <Link to="/" className="flex items-center justify-start text-white cursor-pointer z-10 ml-[20px] w-[120px] md:w-[180px]" onClick={() => {closeMobileMenu(); goToTop();}}>
                <img src={logo} alt="Sumpro Global Sports Agency Logo" width="180" height="38" />
              </Link>
              <div className="absolute right-[40px] block lg:hidden absolute top-[-5px] right-[-20px] translate-x-[-100%] translate-y-[60%] text-3xl h-[40px] w-[40px] cursor-pointer" onClick={handleClick}>
                {click ? <FaTimes className="w-full h-full" /> : <FaBars className="w-full h-full" />}
              </div>
              <ul className={click ? "transition-all absolute top-[80px] lg:top-0 lg:left-0 lg:right-0 opacity-1 flex flex-col lg:flex-row w-full justify-center items-center list-none text-center lg:justify-end active bg-sumpro-bg left-0 duration-[0.6s] pb-8 lg:pb-0" : "duration-[0.6s] transition-all absolute top-[80px] lg:top-0 left-[-100%] lg:left-0 lg:right-0 opacity-1 flex flex-col lg:flex-row w-full justify-center items-center list-none text-center lg:justify-end pr-[60px]"}>
                {menu.map((content, index) => (
                  <li key={index} className="text-white border-solid h-[50px] lg:h-20 border-b-2 border-transparent lg:hover:border-sumpro-green font-semibold uppercase mx-4">
                    <NavLink
                      to={t(content.link)}
                      className={({ isActive }) =>
                        "flex items-center no-underline py-2.5 h-full text-base" + (isActive ? " activated text-sumpro-green" : "")
                      }
                      onClick={() => {closeMobileMenu(); goToTop();}}
                    >
                      {t(content.title)}
                    </NavLink>
                  </li>
                ))}
              </ul>
              <Flags />
            </div>
          </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;