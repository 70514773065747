import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRouteError } from "react-router-dom";
import HeroBanner from "../components/Hero/Herobanner";

export default function ErrorPage() {
  const error = useRouteError();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = "/video-bg-2.jpg";
  const mobileImage = "/video-bg-2.jpg";
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div id="error-page">
      <HeroBanner title="404" image={image} alt="People cheering up for football game" />
      <section id="error" className="container md:py-20 py-10 px-5 lg:px-0 flex flex-wrap md:flex-nowrap odd:flex-row-reverse even:flex-row justify-evenly justify-between gap-8 text-center">
        <article className="w-full max-w-4xl">
          <h1 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Oh noo!</h1>
          <p>You're beyond the borders.</p>
          <p>
            <i>{error.statusText || error.message}</i>
          </p>
          <div className="flex justify-center items-center mb-[60px]">
            <Link to="/" className="border rounded-xl border-sumpro-green hover:bg-sumpro-green font-semibold uppercase text-white mb-2.5 mt-[60px] px-6 py-2 max-w-xs">Reroute</Link>
          </div>
        </article>
      </section>
    </div>
  );
}