import React, { useState, useEffect } from "react";
import HeroBanner from "../components/Hero/Herobanner";
import banner from "../content/cookies-banner-content";

function Cookies() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const desktopImage = banner.desktopImage;
  const mobileImage = banner.mobileImage;
  const image = windowWidth >= 650 ? desktopImage : mobileImage;
  console.log(mobileImage);
  
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return (
    <div className="privacy-policy">
      <HeroBanner title={banner.title} id={banner.id} image={image} alt={banner.alt} key={banner.index} />
      <section id="privacy-policy" className="container md:py-20 py-10 px-5 lg:px-0 flex flex-wrap md:flex-nowrap odd:flex-row-reverse even:flex-row justify-evenly justify-between gap-8">
        <article className="w-full max-w-4xl">
          <section className="md:py-10 py-5">
            <p className="ml-6 mb-4">Like most websites, this one uses cookies. Cookies are small text files stored on your computer by your browser. They're used for many things, such as remembering whether you've visited the site before, so that you remain logged in - or to help us work out how many new website visitors we get each month. They contain information about the use of your computer but don't include personal information about you (they don't store your name, for instance).</p>

            <p className="ml-6 mb-4">This policy explains how cookies are used on SUMPRO website in general - and, below, how you can control the cookies that may be used on this site (not all of them are used on every site).</p>

          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">What are cookies?</h2>

            <p className="ml-6 mb-4">A cookie is a small data file that is placed on your browser or the hardware of your computer or other device to allow a website to recognise you as a user when you return to the website, either for the duration of your visit (using a 'session cookie') or for repeat visits (a 'persistent cookie'). Other similar files work in the same way and we use the word 'cookie' in this policy to refer to all files that collect information in this way.</p>

            <p className="ml-6 mb-4">Cookies are an extremely common technology for remembering certain information about a visitor to a website. The vast majority of websites currently make use of cookies and they are commonly used for a wide range of tasks. We use cookies for the following purposes:</p>

            <ul className="ml-6 list-disc list-inside">
              <li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our Site.</li>
              <li><b>Analytical cookies.</b> They allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our Site works, for example, by ensuring that users are finding what they are looking for easily.</li>
              <li><b>Functionality cookies.</b> These are used to recognise you when you return to our Site and to embed functionality from third party services. This enables us to integrate useful services provided by third party providers into our Site.</li>
              <li><b>Social media cookies/plugins.</b> We include a variety of social media features on the pages of the Site that record your interactions with those features with your accounts with various social networking sites (e.g. LinkedIn). For these to work, the social networking site will set/read cookies through our Site to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.</li>
            </ul>
            <br/>
            <p className="ml-6 mb-4">This Cookie Policy gives you information on the specific cookies used on this Site.</p>
          
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">How to control and delete cookies</h2>

            <p className="ml-6 mb-4">When you accessed the Site, you were presented with a message that notified you of our use of cookies. We had to use a cookie to present you with that notice. If you continue to use the Site, more cookies and other tracking technologies will be placed on your computer as described in this Cookie Policy in order to enhance your user experience whilst on the Site. By continuing to use the Site, you are agreeing to the use of such cookies and tracking technology.</p>

            <p className="ml-6 mb-4">If you wish to restrict or block the cookies which are set by this Site (or, indeed, on any other site) you can do this through your browser settings. The 'Help' function within your browser should tell you how. If you are primarily concerned about third party cookies generated by advertisers, you can turn these off by going to the Your Online Choices site.</p>

            <p className="ml-6 mb-4">Alternatively, you may wish to visit All About Cookies which contains comprehensive information on how to do this on a wide variety of browsers. You will also find details on how to delete cookies from your computer, as well as more general information about cookies. Please note that, as these websites are not owned or operated by us, we are not responsible for any of the content on them.</p>

            <p className="ml-6 mb-4">Please be aware that restricting cookies may mean that you will not be able to take full advantage of all the features or services available on this Site.</p>
          
          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Changes</h2>

            <p className="mb-4">We may make changes to this Cookie Policy at any time by sending you an e-mail with the modified Terms or by posting a copy of them on the Site. Any changes will take effect 7 days after the date of our e-mail or the date on which we post the modified terms on the Site, whichever is the earlier. Your continued use of the Site after that period expires means that you agree to be bound by the modified policy.</p>

          </section>
          <section className="md:py-10 py-5">
            <h2 className="relative lg:text-4xl md:text-3xl text-2xl font-semibold capitalize mb-5 md:mb-7">Contact us</h2>

            <p className="mb-4">If you have any questions or concerns about cookies or your privacy when using this Site, please contact us using the form on our website or via email at info@sumproagency.com.</p>
          </section>

        </article>
      </section>
    </div>
  );
}

export default Cookies;