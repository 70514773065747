const profiles = [
  { 
    name: "Julien C. Briquez", 
    position: "CEO and Licenced FIFA Agent", 
    languadge: "../../assets/en.png",
    image: "profile/new/julien-briquez.jpg"
  },
  { 
    name: "Jimmy Loisel", 
    position: "Data analyst", 
    languadge: "EnFlag",
    image: "profile/new/jimmy-loisel.jpg"
  },
  { 
    name: "Noemi Polgar", 
    position: "Administration & Marketing Director", 
    languadge: "EnFlag",
    image: "profile/new/noemi-polgar.jpg"
  },
  { 
    name: "Cyril Alvarez-Pereyre", 
    position: "Finance advisor", 
    languadge: "EnFlag",
    image: "profile/new/cyril-alvarez-pereyre.jpg"
  },
  { 
    name: "Ilze Spruge", 
    position: "Media & Sponsorship Director", 
    languadge: "EnFlag",
    image: "profile/new/ilze-spruge.jpg"
  },
  { 
    name: "Sébastien Duc", 
    position: "Fitness, Nutrition & Mental Coach",
    languadge: "EnFlag",
    image: "profile/new/sebastien-duc.jpg"
  }
]

export default profiles