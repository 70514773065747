import React from "react";

function ImageSection() {
  return (
    <section className="mx-auto opacity-50">
      <img className="relative" src="/video-bg-2.jpg" alt="futbol stadium" width="1920" height="869" />
    </section>
  )
}

export default ImageSection;