import React from "react";
// import EnFlag from '../../assets/en.png';
// import FrFlag from '../../assets/fr.png';
// import ItFlag from '../../assets/it.png';
// import EsFlag from '../../assets/es.png';
// import PtFlag from '../../assets/pt.png';
// import HuFlag from '../../assets/hu.png';
// import DeFlag from '../../assets/de.png';
// import JpFlag from '../../assets/jp.png';


function ProfileSection(props) {
  const { name, position, image } = props
  
  return (
    <article className="relative w-full max-w-screen-md before:absolute before:w-full before:block before:bg-sumpro-gray before:opacity-30 before:h-full before:z-[-1] z-0">
      <div className="profile picture">
        <img className="w-full" src={image} width="200" height="200" alt={name} />
      </div>
      <div className="text-left p-3">
        <h3 className="font-semibold text-base mb-2">{name}</h3>
        <p className="mb-3.5 text-sm">{position}</p>
        {/* <p className="mb-3.5 grid grid-cols-2"><img src={languadge} width="38" height="38" alt="English flag" /> <img src={languadge} width="38" height="38" alt="English flag" /></p> */}
      </div>
    </article>
  )
}

export default ProfileSection;