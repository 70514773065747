import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

function Footernav(props) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const { title, url } = props

  useEffect(() => {
    window.addEventListener("scroll", () => {
        if (window.scrollY > 400) {
           
        } else {
            
        }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
  };
  
  return (
    <>
      <li className="b-5 mb-6 mr-5 lg:mr-0 cursore-pointer" onClick={() => {handleClick(); goToTop();}}>
        <NavLink to={url} className={({ isActive }) => "flex items-center no-underline uppercase py-2.5 px-4 h-full lg:hover:text-sumpro-green font-semibold text-base " + (isActive ? " activated text-sumpro-green" : "")}>{title}</NavLink>
      </li>
    </>
  )
}

export default Footernav;

